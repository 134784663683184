import React, {useContext, useEffect, useState} from 'react'
import {motion, useCycle} from 'framer-motion'

import AddCart from '../../../images/svgs/addtocart.svg'
import {CartContext} from '../cart/CartProvider'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import {ProductsContext} from './ProductsProvider'
import PropTypes from 'prop-types'
import RedCircle from '../../../images/svgs/redcircle.svg'
import {formatPrice} from '../../shared/utils'

const info = {
  open: {
    height: 'auto',
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    height: 0,
    transition: {
      delay: 0.3,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const ProductThumbnail = ({sku}) => {
  const [symbol, setSymbol] = useState('+')
  const [isExpanded, toggleExpansion] = useCycle(false, true)
  const [inStock, setStock] = useState(true)
  const {add, toggle} = useContext(CartContext)

  useEffect(() => {
    setStock(sku.inventory.quantity === 0 ? false : true)
  }, [])

  return (
    <div key={sku.id} className="text-black flex flex-col w-full xl:w-1/2 font-sans mb-8 px-2 ">
      {/* sku Image */}
      <Link className="" to={`/gallery/${sku.product.name.toLowerCase()}`}>
        <Img
          className="rounded w-full "
          fluid={sku.localFiles[0].childImageSharp.fluid}
          alt={sku.product.name}
        />
      </Link>
      {/* sku info card */}
      <div className="z-50  flex flex-row bg-main justify-between  py-2  mt-2 rounded-lg text-sm font-thin ">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <Link className="" to={`/gallery/${sku.product.name.toLowerCase()}`}>
              <div className="underline  hover:text-white text-lg font-medium">
                {sku.product.name}
              </div>
            </Link>
            <div className="text-xs md:text-xs leading-loose  mx-2">{sku.product.description}</div>
          </div>
          {sku.inventory.quantity === 0 ? (
            ''
          ) : (
            <div className="my-1">{sku.inventory.quantity} remaining</div>
          )}

          <div className="flex flex-col self-start mt-2 ">
            <div className="border-b border-white "></div>
            <div className="text-left w-auto font-mono font-thin  text-sm">
              {formatPrice(sku.price, sku.currency)}
              <div className="border-b border-white "></div>
            </div>

            {sku.inventory.quantity != 0 ? (
              <Link
                to="/cart"
                className="font-bold text-xs  hover:underline hover:text-white  mt-2 font-medium"
                onClick={() => {
                  add(sku.id)
                  toggle(true)
                }}>
                Add To Cart <AddCart className="w-5 h-5 inline-block"></AddCart>
              </Link>
            ) : (
              <>
                <div className="mt-1 flex flex-row">
                  <div className="font-mono text-xs italic">Sold Out</div>
                  <RedCircle className="inline-block h-2 self-start mt-1" />
                </div>
              </>
            )}
          </div>
          <div
            className="flex flex-col justify-end self-end overflow-hidden"
            animate={isExpanded ? 'open' : 'closed'}
            variants={info}></div>
        </div>
      </div>
    </div>
  )
}

ProductThumbnail.propTypes = {
  sku: PropTypes.object.isRequired,
}

export default ProductThumbnail
