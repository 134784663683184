import {Link, Page, PageProps, graphql, useStaticQuery} from 'gatsby'
import React, {useEffect} from 'react'

import Cart from '../components/gallery/cart/Cart'
import Products from '../components/gallery/products/Products'
import SEO from '../components/shared/seo'
import posed from 'react-pose'
import styled from 'styled-components'
import {useLayoutValue} from '../components/ui/layoutProvider'
import {useMediaQuery} from 'react-responsive'

const SlideDiv = posed.div({
  enter: {
    x: 0,
    opacity: 0,
    width: '0%',
    background: 'rgba(211, 211, 211, 1)',
    transition: {
      background: {ease: 'linear', damping: 20, delay: 100},
      width: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
    },
  },
  exit: {
    x: 0,
    width: '100%',
    opacity: 100,
    background: 'rgba(255, 255, 255, 1)',
    transition: {
      background: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
      width: {ease: 'linear', damping: 500, delay: 100, duration: 1000},
    },
  },
})

// styled-component with posed.div
const LinkUnderline = styled(SlideDiv)`
  position: relative;
  width: 100%;
  height: 0.08em;
`

const StyledTitle = styled.div`
  /* font-size: 9rem; */
  letter-spacing: 5rem;
  /* top: 0; */
  line-height: 7rem;
`

const Gallery: React.FC<PageProps<any>> = ({data}) => {
  const {state, dispatch} = useLayoutValue()
  const isMobile = useMediaQuery({maxWidth: 1224})

  return (
    <>
      <SEO
        title="Shop | John Dore"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />

      <div className="  flex flex-col bg-main mb-16 font-sans h-full ">
        <div className=" text-white  w-full  bg-gray tracking-widest">
          <div className="bg-gray pt-20 mx-8 text-5xl md:text-7xl pt-20 leading-header">
            gallery
          </div>
        </div>
        <div className="md:mt-6 justify-start w-full">
          <Cart />
          <Products className="" data={data}></Products>
        </div>
      </div>
      <footer className="bottom-0 fixed z-50 p-2 leading-none font-mono bg-gray  text-sm w-full">
        <div className="mx-2 text-xs inline-block text-white">current exhibit:</div>
        <Link className="italic text-white" to="/work/art/lexp">
          LEXP
        </Link>
      </footer>
    </>
  )
}

export const query = graphql`
  query menuLinks {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
      }
    }
  }
`

export default Gallery
