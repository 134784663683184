import React, {useContext} from 'react'

import {CartContext} from './CartProvider'
import CartItem from './CartItem'
import {Link} from 'gatsby'
import ShoppingCart from '../../../images/svgs/shoppingcart.svg'
import {useLayoutValue} from '../../ui/layoutProvider'

const Cart = () => {
  const {cart, count, mode, toggle} = useContext(CartContext)
  const {state, dispatch} = useLayoutValue()
  return (
    <>
      <Link
        to="/cart"
        className={
          state.isClosed
            ? `z-50 absolute  top-0 left-0 mt-6 ml-16 h-8 w-8`
            : `z-50 fixed  top-0 left-0 mt-6 ml-16 h-8 w-8`
        }
        onClick={() => toggle()}>
        <ShoppingCart className=" inline-block h-6 w-6" />
      </Link>
      <div
        className={
          state.isClosed
            ? `z-50 absolute text-white font-mono text-xss  top-0 left-0 mt-6 ml-24 h-8 w-8`
            : `z-50 fixed text-white font-mono text-xss top-0 left-0 mt-6 ml-24 h-8 w-8`
        }>
        [{count}]
      </div>
    </>
  )
}

export default Cart
