import React, {useContext, useEffect, useState} from 'react'

import {CartContext} from './CartProvider'
import Img from 'gatsby-image'
import {Link} from 'gatsby'
import styled from 'styled-components'

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(price)
}

const CartItem = ({sku, quantity}) => {
  const {remove, set, contents} = useContext(CartContext)
  const [value, setValue] = useState(quantity)

  // const options = Array.from(String(sku.inventory.quantity), Number)
  const unfold = (accumulator, length) =>
    length <= 0 ? accumulator : unfold([length, ...accumulator], length - 1)
  const options = unfold([], sku.inventory.quantity)

  const onChange = (e) => {
    const re = /^[0-9\b]+$/
    // e.preventDefault()
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value <= sku.inventory.quantity) {
        setValue(e.target.value)
        set(sku.id, e.target.value)
      }
    }
  }
  useEffect(() => {
    setValue(quantity)
  }, [])

  return (
    <div className="flex flex-col  my-2 text-black justify-between font-sans " key={sku.id}>
      <div className="flex  w-full">
        <Link
          className=" self-start  w-1/2  md:w-1/12"
          to={`/gallery/${sku.product.name.toLowerCase()}`}>
          <Img
            className=" "
            fluid={sku.localFiles[0].childImageSharp.fluid}
            alt={sku.product.name}
          />
        </Link>
        <div className="flex flex-col w-full justify-between  font-medium  ">
          {/* <div>&times;{count}</div> */}
          <div>
            <Link to={`/gallery/${sku.product.name.toLowerCase()}`}>
              <div className="mx-4  text-bold">{sku.product.name} </div>
            </Link>
            <div className="mx-4 text-xs">{sku.product.description}</div>
          </div>
          <div className="mx-4   font-mono text-bold text-sm leading-loose">
            {formatPrice(sku.price * quantity, sku.currency)}
          </div>
          {/* <div className=" mx-4 leading-loose text-xs italic">
            {sku.inventory.quantity - value}
            <span className="mx-1 text-white"> left in stock</span>
            <div>{sku.inventory.quantity - value === 0 ? 'this is the last one' : ''}</div>
          </div> */}
        </div>
      </div>
      <div className="border-b w-full border-white mt-2"></div>
      <form className="flex flex-row  justify-between items-start  mt-2 text-black w-full">
        <div className="flex flex-row">
          <select
            className="text-black font-mono text-center   w-10"
            id="newQuantity"
            name="newQuantity"
            value={value}
            onChange={(e) => {
              onChange(e)
            }}>
            {options.map((option, i) => {
              return (
                <option key={i} className="px-2 text-black" value={option}>
                  {option}
                </option>
              )
            })}
          </select>
          <div className="mx-2 text-center leading-loose text-xs">in cart</div>
        </div>
        {/* <div className="h-full "></div> */}
        <div className="flex flex-col">
          <button
            className="  text-xs hover:text-white mr-2 leading-loose font-medium text-black "
            onClick={() => {
              remove(sku.id)
            }}>
            {/* &times; */}
            remove
          </button>
        </div>
      </form>
    </div>
  )
}

export default CartItem
