import React, {useContext} from 'react'

import ProductThumbnail from './ProductThumbnail'
import {ProductsContext} from './ProductsProvider'

const Products = () => {
  const {listSkus} = useContext(ProductsContext)
  const skus = listSkus()
  return (
    <div className="flex flex-col w-full md:w-3/4 xl:flex-row flex-wrap justify-start p-8 ">
      {skus.map((sku) => (
        <ProductThumbnail className="" key={sku.id} sku={sku} />
      ))}
    </div>
  )
}

export default Products
